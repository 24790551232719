@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  src: url('https://assets.podium.com/fonts/Graphik-Regular.woff')
    format('woff');
}
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  src: url('https://assets.podium.com/fonts/Graphik-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  src: url('https://assets.podium.com/fonts/Graphik-Semibold.woff')
    format('woff');
}
